import React from 'react'

import Head from "../components/Head"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"

const Contact = () => (
  <div className='contact'>
    <Head />
    <Navigation />
    <div className="wrapper">
      <section className="contact">
          <h1>Get in touch</h1>
          <p>I speak Spanish, English, Danish and a bit of French.</p>
          <a className="email" href="mailto: hola@nanati.com">hola@nanati.com</a>
      </section>
      <Footer />
    </div>
  </div>
)

export default Contact